import type { CreatorPostFormVO } from './type';

import { VALIDATION_ERROR_MESSAGE } from '@/constants';
import { z } from '@/libs/zod';

export const scopeOptions: Array<{
  label: string;
  value: CreatorPostFormVO['scope'];
}> = [
  { label: '全体に公開', value: 'public' },
  { label: '自分のみに公開', value: 'private' },
];

export const MAX_LENGTH = {
  TEXT: 9999,
};

export const creatorPostFormSchema = z
  .object({
    scope: z.enum(['public', 'private']),
    text: z
      .string()
      .max(
        MAX_LENGTH.TEXT,
        VALIDATION_ERROR_MESSAGE.MAX_STRING_LENGTH(MAX_LENGTH.TEXT)
      )
      .optional(),
    image: z.file().optional(),
  })
  .refine(
    ({ text, image }) => {
      return !!text || image != null;
    },
    { path: ['text'], message: 'テキストもしくは画像を入力してください' }
  );

export const formInitialValues: CreatorPostFormVO = {
  scope: 'public',
  text: '',
  image: undefined,
};

export const placeholder: Record<keyof CreatorPostFormVO, string> = {
  scope: '',
  text: '作ったものや作るプロセス、今の気持ちを投稿してください',
  image: '',
};
