import { clsx } from 'clsx';
import { filter } from 'graphql-anywhere';

import type { CreatorCardHeaderProps } from './type';

import { CreatorFollowButton } from '@/components/features/creator';
import { IconDefaultProfile } from '@/components/icons';
import { Image, AnchorLink } from '@/components/ui';
import { ROUTE } from '@/constants';
import { CreatorFollowButtonCreatorsFragmentDoc } from '@/graphql/generated';

export const CreatorCardHeader = ({
  myCreatorId,
  creator,
  isLoggedIn,
  onSuccessFollow,
  onSuccessUnfollow,
}: CreatorCardHeaderProps) => {
  const isFollowed = creator.followers.some(
    (follower) => follower.id === myCreatorId
  );

  return (
    <div
      className={clsx('tw-flex tw-items-center tw-justify-between tw-gap-x-2')}
    >
      <AnchorLink
        href={ROUTE.profile(creator.id)}
        className={clsx('tw-flex-1 tw-flex tw-items-center tw-gap-x-2')}
      >
        <div
          className={clsx(
            'tw-w-10 tw-h-10 tw-rounded-circle tw-overflow-hidden'
          )}
        >
          {creator.profileImage?.url ? (
            <Image
              src={creator.profileImage?.url}
              alt=""
              objectFit="cover"
              size={{ sp: '40px', tablet: '40px', pc: '40px' }}
            />
          ) : (
            <IconDefaultProfile boxSize="40px" />
          )}
        </div>
        <div
          className={clsx(
            'tw-flex-1 tw-text-ui14 tw-font-bold tw-line-clamp-1 tw-break-all'
          )}
        >
          {creator.nickname}
        </div>
      </AnchorLink>
      {creator.id !== myCreatorId && (
        <CreatorFollowButton
          isFollowed={isFollowed}
          isLoggedIn={isLoggedIn}
          creator={filter(CreatorFollowButtonCreatorsFragmentDoc, creator)}
          onSuccessFollow={onSuccessFollow}
          onSuccessUnfollow={onSuccessUnfollow}
        />
      )}
    </div>
  );
};
