import { type MutableRefObject, useMemo } from 'react';

type RefCallback<T> = (newValue: T | null) => void;
type RefObject<T> = MutableRefObject<T | null>;

type DefinedReactRef<T> = RefCallback<T> | RefObject<T>;
type ReactRef<T> = DefinedReactRef<T> | null;

export const assignRef = <T>(ref: ReactRef<T> | undefined, value: T | null) => {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref) {
    ref.current = value;
  }

  return ref;
};

export const useMergeRefs = <T>(...refs: (ReactRef<T> | undefined)[]) => {
  return useMemo(() => {
    if (refs.every((ref) => ref == null)) {
      return null;
    }
    return (node: T) => {
      refs.forEach((ref) => {
        if (ref) assignRef(ref, node);
      });
    };
  }, [refs]);
};
