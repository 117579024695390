export const checkIsImageFile = (file: File) => {
  return file.type.includes('image');
};

export const checkIsUnderFileSizeLimit = (
  file: File,
  maxFileSizeMb: number
) => {
  return file.size <= maxFileSizeMb * 2 ** 20;
};
