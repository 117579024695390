import { clsx } from 'clsx';
import { useRef, forwardRef } from 'react';

import type { InputImageFileProps } from './type';
import type { ChangeEventHandler, MouseEventHandler } from 'react';

import { IconLoader } from '@/components/icons';
import { API_ERROR_MESSAGE, VALIDATION_ERROR_MESSAGE } from '@/constants/error';
import { InputImageFileCreateFilePutUrlDocument } from '@/graphql/generated';
import { useUploadFile, useMergeRefs, useToast } from '@/hooks/utils';
import { useMutationWrapper } from '@/libs/apollo';

import { checkIsImageFile, checkIsUnderFileSizeLimit } from './util';

export const InputImageFile = forwardRef<HTMLInputElement, InputImageFileProps>(
  (
    {
      maxFileSizeMb,
      isDisabled = false,
      isInvalid = false,
      loadingComponent = <IconLoader />,
      children,
      onChange,
    },
    ref
  ) => {
    const { showToast } = useToast();

    const inputFileRef = useRef<HTMLInputElement>(null);
    const refs = useMergeRefs(inputFileRef, ref);

    const [createFilePutUrl, createFilePurUrlMutationState] =
      useMutationWrapper(InputImageFileCreateFilePutUrlDocument);

    const fileUploadMutation = useUploadFile();

    /** ファイル選択ボタン押下処理 */
    const handleClickSelectButton: MouseEventHandler<HTMLButtonElement> = (
      e
    ) => {
      if (inputFileRef?.current) {
        inputFileRef.current.click();
      }
      e.stopPropagation();
    };

    /** ファイル選択時の処理 */
    const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      if (!e.target.files || e.target.files.length === 0) {
        return;
      }

      if (!checkIsImageFile(e.target.files[0])) {
        showToast('error', VALIDATION_ERROR_MESSAGE.FILE_FORMAT);
        onChange(null);
        return;
      }

      if (
        maxFileSizeMb !== undefined &&
        !checkIsUnderFileSizeLimit(e.target.files[0], maxFileSizeMb)
      ) {
        showToast('error', VALIDATION_ERROR_MESSAGE.FILE_SIZE(maxFileSizeMb));
        onChange(null);
        return;
      }

      createFileUploadUrl(e.target.files[0]);

      // HACK: 同じファイルを連続して選択できるようにする
      e.target.value = '';
    };

    /** ファイルアップロード先認証付きURLの取得処理 */
    const createFileUploadUrl = (file: File) => {
      createFilePutUrl({
        variables: { fileName: file.name },
        onCompleted: ({ generateUrlForPutFile: { url } }) => {
          uploadFile(url, file);
        },
        onError: () => {
          showToast('error', API_ERROR_MESSAGE.UNKNOWN);
        },
      });
    };

    /** ファイルアップロード処理 */
    const uploadFile = (url: string, file: File) => {
      fileUploadMutation.execute({
        url,
        file,
        onSuccess: () => {
          const [urlWithoutQueryString] = url.split('?');
          onChange({ name: file.name, url: urlWithoutQueryString });
        },
        onError: () => {
          showToast('error', API_ERROR_MESSAGE.UNKNOWN);
        },
      });
    };

    return (
      <div className={clsx(isDisabled && 'tw-cursor-not-allowed')}>
        <span
          className={clsx(
            isInvalid &&
              'tw-outline tw-outline-2 tw-outline-red-500 tw-rounded-md'
          )}
        >
          <button
            type="button"
            disabled={
              isDisabled ||
              createFilePurUrlMutationState.loading ||
              fileUploadMutation.loading
            }
            onClick={handleClickSelectButton}
          >
            {!createFilePurUrlMutationState.loading &&
            !fileUploadMutation.loading
              ? children
              : loadingComponent}
          </button>
        </span>

        <input
          type="file"
          ref={refs}
          tabIndex={-1}
          onChange={handleChange}
          disabled={isDisabled}
          accept="image/*"
          multiple={false}
          className={clsx('tw-hidden')}
        />
      </div>
    );
  }
);

InputImageFile.displayName = 'InputImageFile';
