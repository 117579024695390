import type { CreatorPostFormVO, CreatorPostFormModalProps } from './type';
import type { SaveCreatorPostInputPost } from '@/graphql/generated.creator-anonymous';

export const convertFormDataToSaveCreatorPostInput = ({
  postId,
  text,
  image,
  scope,
  parentPostId,
}: CreatorPostFormVO & {
  postId?: number;
  parentPostId?: number | null;
}): SaveCreatorPostInputPost => {
  return {
    id: postId,
    text: !!text ? text : null,
    image: image != null ? { url: image.url } : null,
    isPrivate: scope === 'private',
    parentPostId,
  };
};

export const convertCreatorPostToFormData = ({
  text,
  image,
  isPrivate,
}: NonNullable<
  CreatorPostFormModalProps['creatorPost']
>): CreatorPostFormVO => {
  return {
    scope: isPrivate ? 'private' : 'public',
    text: text != null ? text : undefined,
    image: image != null ? { url: image.url, name: 'dummy-name' } : null,
  };
};
