import { clsx } from 'clsx';
import { Section, H } from 'react-headings';

import type { AuthRequiredLoginModalProps } from './type';

import { AuthSignupModal, AuthLoginModal } from '@/components/features/auth';
import { BaseModal, Button } from '@/components/ui';
import { useDisclosure } from '@/hooks/utils';

export const AuthRequiredLoginModal = ({
  isOpen,
  onClose,
}: AuthRequiredLoginModalProps) => {
  const loginModalState = useDisclosure();
  const signupModalState = useDisclosure();

  const handleClickLogin = () => {
    signupModalState.close();
    loginModalState.open();
  };

  const handleClickSignup = () => {
    loginModalState.close();
    signupModalState.open();
  };

  const handleAfterLogin = () => {
    onClose();
  };

  return (
    <BaseModal
      isOpen={isOpen}
      ariaLabel="ログイン必須機能通知"
      onClose={onClose}
    >
      <div className="md:tw-max-w-[360px]">
        <Section
          component={
            <H className="tw-text-center tw-text-xl tw-font-bold">
              Koubo会員限定の機能です
            </H>
          }
        >
          <p className="tw-mt-10 tw-text-base">
            続けるには新規会員登録（無料）またはログインしてください。
          </p>

          <div className={clsx('tw-flex tw-flex-col tw-mt-6 tw-gap-y-6')}>
            <Button isBlock theme="fill" onClick={handleClickSignup}>
              無料会員登録
            </Button>
            <Button isBlock theme="outline" onClick={handleClickLogin}>
              ログイン
            </Button>
          </div>
          <AuthSignupModal
            isOpen={signupModalState.isOpen}
            onClose={signupModalState.close}
            onClickLogin={handleClickLogin}
          />
          <AuthLoginModal
            isOpen={loginModalState.isOpen}
            onClose={loginModalState.close}
            onClickSignup={handleClickSignup}
            onAfterLogin={handleAfterLogin}
          />
        </Section>
      </div>
    </BaseModal>
  );
};
