import { extractHashtagsWithIndices } from '@draft-js-plugins/hashtag';
import { extractLinks } from '@draft-js-plugins/linkify';

import { ROUTE } from '@/constants';

import { stringifyUrl } from '../url';

export const linkifyCreatorPostText = (text: string) => {
  const links = extractLinks(text) ?? [];
  const hashtags = extractHashtagsWithIndices(text)
    // NOTE: URL内のアンカーリンクもハッシュタグとして検出されてしまうのでそれは除外
    .filter(
      (hashtag) =>
        !links.some(
          (link) =>
            link.index <= hashtag.indices[0] &&
            hashtag.indices[0] <= link.lastIndex
        )
    );
  const replacements: {
    text: string;
    href: string;
    startIndex: number;
    endIndex: number;
  }[] = [
    ...links.map(
      (link) =>
        ({
          text: link.raw,
          href: link.raw,
          startIndex: link.index,
          endIndex: link.lastIndex,
        } as const)
    ),
    ...hashtags.map(
      (tag) =>
        ({
          text: `#${tag.hashtag}`,
          href: stringifyUrl(
            ROUTE.tsukuLogSearch({
              tab: 'creatorPost',
              hashTag: tag.hashtag,
            })
          ),
          startIndex: tag.indices[0],
          endIndex: tag.indices[1],
        } as const)
    ),
  ].sort((a, b) => a.startIndex - b.startIndex);
  let offset = 0;
  const result = replacements.reduce<string>((result, replacement) => {
    const linkHtml = `<a href="${replacement.href}" class="tw-text-link hover:tw-opacity-70 tw-transition tw-duration-200 tw-break-all" target="_blank" rel="noreferrer nofollow" onClick="event.preventDefault()">${replacement.text}</a>`;
    const newResult =
      result.slice(0, replacement.startIndex + offset) +
      linkHtml +
      result.slice(replacement.endIndex + offset);
    offset += linkHtml.length - (replacement.endIndex - replacement.startIndex);
    return newResult;
  }, text);
  return result;
};
