import { Transition } from '@headlessui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { clsx } from 'clsx';
import { Fragment, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLockedBody } from 'usehooks-ts';

import type { CreatorPostFormModalProps, CreatorPostFormVO } from './type';

import { IconClose, IconAddImage } from '@/components/icons';
import {
  Button,
  FormFieldSelectSingle,
  Image,
  BaseModal,
} from '@/components/ui';
import { ModalOverlay } from '@/components/ui/modal/ModalOverlay';
import { CreatorPostFormModalUpsertCreatorPostDocument } from '@/graphql/generated.creator-anonymous';
import { useDisclosure, useToast } from '@/hooks/utils';
import { useMutationWrapper } from '@/libs/apollo';

import { FormFieldImageFile } from './FormFieldImageFile';
import { FormFieldPostText } from './FormFieldPostText';
import {
  creatorPostFormSchema,
  formInitialValues,
  placeholder,
  MAX_LENGTH,
  scopeOptions,
} from './const';
import {
  convertFormDataToSaveCreatorPostInput,
  convertCreatorPostToFormData,
} from './util';

export const CreatorPostFormModal = ({
  isOpen,
  creatorPost,
  onClose,
  onSuccessUpsert,
}: CreatorPostFormModalProps) => {
  useLockedBody(isOpen, 'root');
  const { showToast } = useToast();

  const [upsertCreatorPostMutation, upsertCreatorPostMutationState] =
    useMutationWrapper(CreatorPostFormModalUpsertCreatorPostDocument);

  const {
    control,
    formState,
    handleSubmit,
    watch,
    setValue,
    trigger,
    reset,
    setFocus,
  } = useForm<CreatorPostFormVO>({
    mode: 'onBlur',
    resolver: zodResolver(creatorPostFormSchema),
    defaultValues:
      creatorPost != null
        ? convertCreatorPostToFormData(creatorPost)
        : formInitialValues,
  });

  const watchedInput = watch();

  const handlePost = (data: CreatorPostFormVO) => {
    const convertedData = convertFormDataToSaveCreatorPostInput({
      ...data,
      postId: creatorPost?.id,
      parentPostId: creatorPost?.parentPostId,
    });
    upsertCreatorPostMutation({
      variables: { creatorPostInput: convertedData },
      onCompleted: ({ saveCreatorPost: { post } }) => {
        if (onSuccessUpsert !== undefined) {
          onSuccessUpsert(post);
        }
        onClose();
        showToast(
          'success',
          creatorPost == null
            ? 'つくログを投稿しました'
            : 'つくログを保存しました'
        );
      },
    });
  };

  const handleClickClose = () => {
    onClose();
  };

  const handleDeleteImage = () => {
    setValue('image', undefined);
    trigger();
  };

  const imageCountAlertModalState = useDisclosure();

  const handleOpenImageCountAlertModal = () => {
    imageCountAlertModalState.open();
  };

  const handleCloseImageCountAlertModal = () => {
    imageCountAlertModalState.close();
  };

  useEffect(() => {
    reset(
      creatorPost != null
        ? convertCreatorPostToFormData(creatorPost)
        : formInitialValues
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, setFocus]);

  setFocus('text');

  return (
    <>
      {isOpen && <ModalOverlay />}
      <Transition
        as={Fragment}
        show={isOpen}
        enter={clsx(
          'tw-transform tw-transition tw-ease-in-out tw-duration-200 md:tw-transform-none'
        )}
        enterFrom={clsx('tw-translate-y-full md:tw-opacity-0')}
        enterTo={clsx('tw-translate-y-0 md:tw-opacity-100')}
        leave={clsx(
          'tw-transform tw-transition tw-ease-in-out tw-duration-200 md:tw-transform-none'
        )}
        leaveFrom={clsx('tw-translate-y-0 md:tw-opacity-100')}
        leaveTo={clsx('tw-translate-y-full md:tw-opacity-0')}
      >
        <div
          className={clsx('tw-fixed tw-inset-0 tw-overflow-y-auto tw-z-modal')}
        >
          <div
            className={clsx(
              'tw-absolute tw-w-full tw-flex tw-items-end tw-justify-center tw-min-h-screen',
              'md:tw-items-center'
            )}
          >
            <div
              role="dialog"
              aria-modal
              aria-label="つくログ作成・編集"
              className={clsx(
                'tw-relative tw-min-w-full tw-max-w-full tw-h-screen tw-flex tw-flex-col tw-bg-white',
                'md:tw-min-w-[552px] md:tw-w-[552px] md:tw-h-auto md:tw-max-h-[80dvh] md:tw-rounded-2xl'
              )}
            >
              {/* for PC modal close button */}
              <button
                aria-label="閉じる"
                className={clsx(
                  'tw-absolute -tw-top-12 tw-right-0 tw-text-white',
                  'hover:tw-opacity-70 tw-transition tw-duration-200',
                  'tw-invisible md:tw-visible'
                )}
                onClick={handleClickClose}
              >
                <IconClose boxSize="40px" />
              </button>

              <section
                className={clsx(
                  'tw-z-sticky tw-top-0',
                  'tw-min-h-[60px] tw-px-spContentSide tw-flex tw-items-center tw-justify-between tw-bg-white',
                  'tw-border-b tw-border-gray-300',
                  'md:tw-hidden'
                )}
              >
                <button
                  aria-label="閉じる"
                  className={clsx(
                    'hover:tw-opacity-70 tw-transition tw-duration-200'
                  )}
                  onClick={handleClickClose}
                >
                  <IconClose boxSize="24px" />
                </button>
                <Button
                  theme="fill"
                  size="sm"
                  isBlock={false}
                  disabled={!formState.isValid}
                  onClick={handleSubmit(handlePost)}
                >
                  {creatorPost == null ? '投稿する' : '保存する'}
                </Button>
              </section>

              <div
                className={clsx(
                  'tw-fixed tw-bottom-4 tw-right-2 tw-shadow-[0_4px_12px_rgba(0,0,0,0.2)]',
                  'tw-h-14 tw-w-14 tw-flex tw-items-center tw-justify-center',
                  'tw-bg-gray-100 tw-rounded-circle',
                  'hover:tw-opacity-70 tw-transition tw-duration-200',
                  'md:tw-hidden'
                )}
              >
                {watchedInput.image?.url == null ? (
                  <FormFieldImageFile
                    id="image"
                    showLabel={false}
                    showError={false}
                    control={control}
                  >
                    <IconAddImage />
                  </FormFieldImageFile>
                ) : (
                  <button
                    type="button"
                    onClick={handleOpenImageCountAlertModal}
                  >
                    <IconAddImage />
                  </button>
                )}
              </div>

              <form
                className={clsx(
                  'tw-py-4 tw-px-spContentSide tw-overflow-y-auto tw-hidden-scrollbar',
                  'md:tw-p-10'
                )}
              >
                {!creatorPost?.parentPostId && (
                  <div className={clsx('tw-w-fit')}>
                    <FormFieldSelectSingle
                      id="scope"
                      name="scope"
                      size="sm"
                      showLabel={false}
                      showError={false}
                      options={scopeOptions}
                      control={control}
                    />
                  </div>
                )}
                <div className={clsx('tw-mt-6')}>
                  <FormFieldPostText
                    id="text"
                    showLabel={false}
                    showError={false}
                    placeholder={placeholder.text}
                    control={control}
                  />
                </div>
                {watchedInput.image?.url != null && (
                  <div
                    className={clsx(
                      'tw-relative tw-mt-4 tw-w-max tw-max-w-full tw-rounded-lg tw-overflow-hidden'
                    )}
                  >
                    <Image
                      src={watchedInput.image?.url}
                      alt=""
                      objectFit="contain"
                    />
                    <button
                      type="button"
                      aria-label="画像削除"
                      className={clsx(
                        'tw-absolute tw-top-2 tw-right-2',
                        'tw-h-6 tw-w-6 tw-flex tw-items-center tw-justify-center',
                        'tw-text-white tw-bg-black tw-rounded-circle',
                        'hover:tw-opacity-70 tw-transition tw-duration-200'
                      )}
                      onClick={handleDeleteImage}
                    >
                      <IconClose boxSize="16px" />
                    </button>
                  </div>
                )}
              </form>
              <span
                className={clsx(
                  'tw-py-2 tw-px-spContentSide tw-text-ui12 tw-text-gray-600',
                  'md:tw-py-4 md:tw-px-10'
                )}
              >{`${watchedInput.text?.length ?? 0}/${
                MAX_LENGTH.TEXT
              }文字`}</span>

              <section
                className={clsx(
                  'tw-hidden md:tw-block',
                  'tw-bg-white tw-rounded-b-2xl'
                )}
              >
                <div
                  className={clsx(
                    'tw-flex tw-items-center tw-justify-between',
                    'tw-border-t tw-border-gray-300',
                    'tw-py-4 tw-pr-4 tw-pl-10'
                  )}
                >
                  {watchedInput.image?.url == null ? (
                    <FormFieldImageFile
                      id="image"
                      showLabel={false}
                      showError={false}
                      control={control}
                    >
                      <span
                        className={clsx(
                          'hover:tw-opacity-50 tw-transition tw-duration-200'
                        )}
                      >
                        <IconAddImage />
                      </span>
                    </FormFieldImageFile>
                  ) : (
                    <button
                      type="button"
                      onClick={handleOpenImageCountAlertModal}
                    >
                      <span
                        className={clsx(
                          'hover:tw-opacity-50 tw-transition tw-duration-200'
                        )}
                      >
                        <IconAddImage />
                      </span>
                    </button>
                  )}

                  <Button
                    theme="fill"
                    isBlock={false}
                    disabled={!formState.isValid}
                    isLoading={upsertCreatorPostMutationState.loading}
                    onClick={handleSubmit(handlePost)}
                  >
                    {creatorPost == null ? '投稿する' : '保存する'}
                  </Button>
                </div>
              </section>

              <BaseModal
                isOpen={imageCountAlertModalState.isOpen}
                ariaLabel="画像投稿数上限警告"
                onClose={handleCloseImageCountAlertModal}
              >
                <p className={clsx('tw-mb-6 tw-text-body16 tw-text-center')}>
                  挿入できる画像は１枚です。
                  <br />
                  画像を変更するためには、一度画像を削除してください。
                </p>
                <Button
                  theme="outline"
                  onClick={handleCloseImageCountAlertModal}
                >
                  OK
                </Button>
              </BaseModal>
            </div>
          </div>
        </div>
      </Transition>
    </>
  );
};
