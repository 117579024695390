import { MeDocument } from '@/graphql/generated';
import { useQueryWrapper } from '@/libs/apollo';

export function useUser() {
  const { data, error, loading } = useQueryWrapper(MeDocument);

  const user = data?.me;

  return {
    isLoggedIn: !!user?.id,
    user: user?.id ? user : null,
    error,
    isLoading: loading,
  };
}
