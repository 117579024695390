import type { IconBaseProps } from '@/components/icons/type';

export const IconAddImage = ({ boxSize = '2em' }: IconBaseProps) => {
  return (
    <svg
      width={boxSize}
      height={boxSize}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M30 21.3098H26.6667V17.9647C26.6667 17.59 26.3733 17.2957 26 17.2957H24.6667C24.2933 17.2957 24 17.59 24 17.9647V21.3098H20.6667C20.2933 21.3098 20 21.6042 20 21.9788V23.3169C20 23.6916 20.2933 23.9859 20.6667 23.9859H24V27.3311C24 27.7057 24.2933 28.0001 24.6667 28.0001H26C26.3733 28.0001 26.6667 27.7057 26.6667 27.3311V23.9859H30C30.3733 23.9859 30.6667 23.6916 30.6667 23.3169V21.9788C30.6667 21.6042 30.3733 21.3098 30 21.3098Z"
          fill="#606971"
        />
        <path
          d="M10.228 15.3287C10.4946 15.0075 11.0013 15.0075 11.268 15.3287L13.4146 17.9914L18.148 12.1441C18.4146 11.8229 18.9213 11.8229 19.188 12.1441L23.0013 16.8674C23.3613 16.3322 23.9746 15.9575 24.668 15.9575H26.0013C27.0946 15.9575 28.0013 16.8674 28.0013 17.9646V19.9717H29.3346V8.00949C29.3346 6.52424 28.1346 5.33337 26.668 5.33337H5.33464C3.85464 5.33337 2.66797 6.52424 2.66797 8.00949V21.39C2.66797 22.8619 3.85464 24.0662 5.33464 24.0662H18.8146C18.7213 23.8387 18.668 23.5845 18.668 23.3168V21.9788C18.668 21.7781 18.708 21.5774 18.7613 21.39H6.73463C6.17464 21.39 5.85464 20.7344 6.21464 20.2928L10.228 15.3287ZM5.33464 10.0166C5.33464 8.90598 6.22797 8.00949 7.33464 8.00949C8.4413 8.00949 9.33463 8.90598 9.33463 10.0166C9.33463 11.1272 8.4413 12.0237 7.33464 12.0237C6.22797 12.0237 5.33464 11.1272 5.33464 10.0166Z"
          fill="#606971"
        />
      </g>
    </svg>
  );
};
