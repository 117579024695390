import { clsx } from 'clsx';
import { useState } from 'react';

import type { CreatorFollowButtonProps } from './type';

import { AuthRequiredLoginModal } from '@/components/features/auth';
import { Button, BaseModal } from '@/components/ui';
import {
  CreatorFollowButtonFollowCreatorDocument,
  CreatorFollowButtonUnfollowCreatorDocument,
} from '@/graphql/generated.creator-anonymous';
import { useDisclosure } from '@/hooks/utils';
import { useMutationWrapper } from '@/libs/apollo';

export const CreatorFollowButton = ({
  creator,
  isFollowed,
  isLoggedIn,
  onSuccessFollow,
  onSuccessUnfollow,
}: CreatorFollowButtonProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const unfollowAlertModalState = useDisclosure();
  const authRequiredModalState = useDisclosure();

  const [followMutation, followMutationState] = useMutationWrapper(
    CreatorFollowButtonFollowCreatorDocument
  );
  const [unfollowMutation, unfollowMutationState] = useMutationWrapper(
    CreatorFollowButtonUnfollowCreatorDocument
  );

  const handleFollow = () => {
    followMutation({
      variables: { creatorId: creator.id },
      onCompleted: ({ followCreator }) => {
        if (onSuccessFollow !== undefined) {
          onSuccessFollow(followCreator.creatorId);
        }
      },
    });
  };

  const handleUnfollow = () => {
    unfollowMutation({
      variables: { creatorId: creator.id },
      onCompleted: ({ unfollowCreator }) => {
        if (onSuccessUnfollow !== undefined) {
          onSuccessUnfollow(unfollowCreator.creatorId);
        }
      },
    });
    unfollowAlertModalState.close();
  };

  return (
    <>
      {isFollowed ? (
        <>
          <span
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <Button
              theme={isHovered ? 'negative' : 'outline'}
              size="sm"
              isBlock={false}
              onClick={
                isLoggedIn
                  ? unfollowAlertModalState.open
                  : authRequiredModalState.open
              }
            >
              {isHovered ? 'フォロー解除' : 'フォロー中'}
            </Button>
          </span>
          <BaseModal
            isOpen={unfollowAlertModalState.isOpen}
            title={`${creator.nickname}さんをフォロー解除しますか？`}
            ariaLabel="フォロー解除確認"
            onClose={unfollowAlertModalState.close}
            closeOnOverlayClick={false}
            withCloseButton={false}
            maxWidthPx={400}
          >
            <p className={clsx('tw-text-body14')}>
              このアカウントのツイートはホームタイムラインに表示されなくなります。
              <br />
              ツイートが非公開になっている場合を除き、プロフィールを表示することはできます。
            </p>
            <div className={clsx('tw-mt-3 tw-flex tw-items-center tw-gap-x-4')}>
              <Button
                theme="outline"
                size="sm"
                onClick={unfollowAlertModalState.close}
              >
                キャンセル
              </Button>
              <Button
                theme="negative"
                size="sm"
                isLoading={unfollowMutationState.loading}
                onClick={handleUnfollow}
              >
                フォロー解除
              </Button>
            </div>
          </BaseModal>
        </>
      ) : (
        <Button
          theme="fill"
          size="sm"
          isBlock={false}
          isLoading={followMutationState.loading}
          onClick={isLoggedIn ? handleFollow : authRequiredModalState.open}
        >
          フォロー
        </Button>
      )}
      <AuthRequiredLoginModal
        isOpen={authRequiredModalState.isOpen}
        onClose={authRequiredModalState.close}
      />
    </>
  );
};
