import Editor from '@draft-js-plugins/editor';
import createHashtagPlugin from '@draft-js-plugins/hashtag';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import { ContentState, EditorState } from 'draft-js';
import { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';

import type { FormFieldPostTextProps } from './type';

import { FormFieldWrapper } from '@/components/ui';
import { extractWrapperProps } from '@/components/ui/form/FormFieldWrapper/util';

import hashtagStyle from './hashtagStyle.module.scss';
import { MAX_LENGTH } from '../const';

import 'draft-js/dist/Draft.css'; // Draft.jsの基本スタイル
import '@draft-js-plugins/hashtag/lib/plugin.css';
import '@draft-js-plugins/linkify/lib/plugin.css';

const hashtagPlugin = createHashtagPlugin({
  theme: hashtagStyle,
});
const linkifyPlugin = createLinkifyPlugin({
  theme: { link: 'tw-text-link' },
});

export const FormFieldPostText = ({
  control,
  shouldUnregister,
  resetEditor,
  ...props
}: FormFieldPostTextProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name: 'text', control, shouldUnregister });

  // NOTE: defaultValueを再生成しないようにStateで設定する
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromText(field.value ?? '')
    )
  );

  const { wrapProps, rest: inputProps } = extractWrapperProps({
    ...props,
    name: 'text',
  });

  const handleChange = (editorState: EditorState) => {
    const plainText = editorState.getCurrentContent().getPlainText();
    if (plainText.length >= MAX_LENGTH.TEXT) return;

    setEditorState(editorState);
    field.onChange(editorState.getCurrentContent().getPlainText());
  };

  useEffect(() => {
    if (!resetEditor) return;
    setEditorState(EditorState.createEmpty());
  }, [resetEditor]);

  return (
    <FormFieldWrapper error={error} {...wrapProps}>
      <Editor
        placeholder={inputProps.placeholder}
        editorState={editorState}
        plugins={[linkifyPlugin, hashtagPlugin]}
        onChange={handleChange}
        onBlur={field.onBlur}
      />
    </FormFieldWrapper>
  );
};
