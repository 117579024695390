import { useController } from 'react-hook-form';

import type { FormFieldImageFileProps } from './type';

import { FormFieldWrapper, InputImageFile } from '@/components/ui';
import { extractWrapperProps } from '@/components/ui/form/FormFieldWrapper/util';

export const FormFieldImageFile = ({
  control,
  shouldUnregister,
  ...props
}: FormFieldImageFileProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name: 'image', control, shouldUnregister });

  const { wrapProps, rest: inputProps } = extractWrapperProps({
    ...props,
    name: 'image',
  });

  return (
    <FormFieldWrapper error={error} {...wrapProps}>
      <InputImageFile
        data-testid={name}
        isInvalid={error !== undefined}
        {...inputProps}
        {...field}
      />
    </FormFieldWrapper>
  );
};
